<app-header></app-header>

<div class="home-sales-section">
  <div class="container">
    <div class="row align-items-end">
      <div class="col-sm-12 col-lg-6 ps-2 pe-2 ps-lg-4 pe-lg-5">
        <div class="home-heading mt-md-5 mt-sm-4">
          <h2>Life Events & Major Changes</h2>
        </div>
        <hr class="line" />
        <div>
          <div class="mt-1">
            If you decide to sell your home, we will help you transfer the
            solar-system obligations to the buyer, so they can immediately enjoy
            the clean power your home’s solar system generates. If you’ve
            decided to refinance your home, we will work with you and your
            lender to release the filings so you can get this accomplished.
          </div>
          <div class="mt-3">
            You can find more information on your options in the
            <a [routerLink]="['/faq']">FAQs</a>
            and on this page. However, you should also review the information in
            your solar agreement. This will have the most accurate and complete
            description of your home sale options. Contact us early in the sale
            (ideally within 30 days). We can help you understand the options and
            take the appropriate next steps.
          </div>
          <div class="refinancing-home-form mt-3">

          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-6 ps-2 pe-2 ps-lg-4 pe-lg-4 pt-3">
        <div class="major-changes-image"></div>
      </div>
    </div>
  </div>
</div>
<div class="select-option">
  <div class="container">
    <div class="row pt-5 pb-5" id="request-forms">
      <div class="col-sm-12 col-lg-6 mt-2 mt-lg-5 details-card" id="refinance-information-request">
        <div>
          <div class="text-center">
            <img src="/assets/book.png" alt="book-image" />
          </div>
          <h2 class="mt-4">Refinancing your home</h2>
          <p class="mt-3">
            Before refinancing your home, you will need to provide us with the
            information below.
          </p>
        </div>
        <div class="refinancing-home-form">
          <a [routerLink]="['/life-events/form/refinance-information-request']">Go To Form</a>
        </div>
      </div>
      <div class="col-sm-12 col-lg-6 mt-2 mt-lg-5 details-card" id="home-sale-information">
        <div>
          <div class="text-center">
            <img src="/assets/solar.png" alt="solar-image" />
          </div>
          <h2 class="mt-4">Selling your home</h2>
          <p class="mt-3">
            Changes to current lease due to sale of your home. Must be completed
            prior to final close of sales.
          </p>
        </div>
        <div class="refinancing-home-form">
          <a [routerLink]="['/life-events/form/home-sale-information/escrow-facilitated-transactions-request']">Go To
            Form (Escrow)</a>
        </div>
        <div class="refinancing-home-form mt-3">
          <a [routerLink]="['/life-events/form/home-sale-information/lawyer-facilitated-transactions-request']">Go To
            Form (Non-Escrow)</a>
        </div>
      </div>
      <div class="col-sm-12 col-lg-6 mt-2 mt-lg-5 details-card" id="solar-agreement-amendment-request">
        <div>
          <div class="text-center">
            <img src="/assets/home-image2.png" alt="home-image" />
          </div>
          <h2 class="mt-4">Lease Changes</h2>
          <p class="mt-3">
            Changes to the names on the lease can occur for several reasons
            including divorce, the passing of a lessee, or the need.
          </p>
        </div>
        <div class="refinancing-home-form">
          <a [routerLink]="['/life-events/form/solar-agreement-amendment-request']">Go To Form</a>
        </div>
      </div>
      <div class="col-sm-12 col-lg-6 mt-2 mt-lg-5 details-card" id="system-purchase-or-prepayment-request">
        <div>
          <div class="text-center">
            <img src="/assets/noun-money-5204439-1283C6.png" alt="money-image" />
          </div>
          <h2 class="mt-4">System Purchase or Prepayment</h2>
          <p class="mt-3">
            To calculate an up to date price for buying out your system, please
            fill out the form below.
          </p>
        </div>
        <div class="refinancing-home-form">
          <a [routerLink]="['/life-events/form/system-purchase-or-prepayment-request']">Go To Form</a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="sales-fqa-section">
  <div class="container pt-sm-2 pt-md-2 mt-lg-5 pb-5">
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <div class="general-faq-image"></div>
      </div>
      <div class="col-sm-12 col-lg-6 ps-sm-2 ps-lg-5">
        <div>
          <h2 class="general-faq-title">General FAQs</h2>
        </div>
        <div class="row pt-sm-3">
          <div class="col-12 p-0 mt-4 mt-sm-4 mt-md-4 mt-lg-5">
            <div class="accordion accordion-flush" id="accordionFlush">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-heading1">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
                    What happens at the end of my lease?
                  </button>
                </h2>
                <div id="flush-collapse1" class="accordion-collapse collapse" aria-labelledby="flush-heading1"
                  data-bs-parent="#accordionFlush">
                  <div class="accordion-body">
                    <ul>
                      <li>
                        When the lease reaches its scheduled term, you may extend the lease term. If you do not extend
                        the lease term or request removal of the system. Please review your lease contract for more
                        information. If you have questions or concerns, please email <a href="mailto:adt@sprucepower.com">adt&#64;sprucepower.com </a>
                        or call
                        <a href="tel:+18443198737">
                          (844) 319-8737
                        </a>
                        .
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-heading2">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
                    What happens when a UCC filing is expired?
                  </button>
                </h2>
                <div id="flush-collapse2" class="accordion-collapse collapse" aria-labelledby="flush-heading2"
                  data-bs-parent="#accordionFlush">
                  <div class="accordion-body">
                    <ul>
                      <li>
                        A UCC filing is good for 5 years, if there is no continuation filed, then the UCC is expired and
                        no termination documentation is required.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-heading2">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                    What happens in the event of a death?
                  </button>
                </h2>
                <div id="flush-collapse3" class="accordion-collapse collapse" aria-labelledby="flush-heading3"
                  data-bs-parent="#accordionFlush">
                  <div class="accordion-body">
                    <ul>
                      <li>
                        In the event of a death, it does not automatically get transferred to a spouse or an estate.
                      </li>
                      <li>
                          To change ownership to the surviving spouse, estate, a trust, or other, please visit
                          <a href="https://sprucepower.com/life-events"> https://sprucepower.com/life-events </a>
                          and click
                          <a (click)="scrollToSection('request-forms')" class="scroll-button">“Go To Form”</a>
                          under the Lease Changes Section to submit your request online. Alternatively, please send the
                          completed Life Event form to <a href="mailto:adt@sprucepower.com">adt&#64;sprucepower.com</a>
                          or call
                          <a href="tel:+18443198737">
                            (844) 319-8737
                          </a>
                          for more information with proper documentation.
                      </li>
                      <li>
                        What Documents are accepted?
                        <ul>
                          <li>
                              We accept a death certificate, Executor of estate, Trust or will document, surrogate of Estate,
                              affidavit, and trustee deed.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-heading4">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                    How can I sign my documents?
                  </button>
                </h2>
                <div id="flush-collapse4" class="accordion-collapse collapse" aria-labelledby="flush-heading4"
                  data-bs-parent="#accordionFlush">
                  <div class="accordion-body">
                    <ul>
                      <li>
                        All documents sent via DocuSign must be completed with an electronic signature.
                      </li>
                      <li>
                        A credit application must be wet signed.
                      </li>
                      <li>
                        The escrow packet has the option to be signed electronically or with a wet signature.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-heading5">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                    I need foreclosure verbiage, do you provide this?
                  </button>
                </h2>
                <div id="flush-collapse5" class="accordion-collapse collapse" aria-labelledby="flush-heading5"
                  data-bs-parent="#accordionFlush">
                  <div class="accordion-body">
                    <ul>
                      <li>
                        A Fannie Mae Addendum may be provided upon request. There is a $75 service charge for this
                        addendum.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-heading6">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse6"
                    aria-expanded="false" aria-controls="flush-collapse6">
                    Who is responsible for paying the Service Charge?
                  </button>
                </h2>
                <div id="flush-collapse6" class="accordion-collapse collapse" aria-labelledby="flush-heading6"
                  data-bs-parent="#accordionFlush">
                  <div class="accordion-body">
                    <ul>
                      <li>
                        Spruce Power does not designate the responsible party between buyer or seller.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-heading8">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse8" aria-expanded="false" aria-controls="flush-collapse8">
                    Where can I find residential documents requested such as Title reports and filings?
                  </button>
                </h2>
                <div id="flush-collapse8" class="accordion-collapse collapse" aria-labelledby="flush-heading8"
                  data-bs-parent="#accordionFlush">
                  <div class="accordion-body">
                    <ul>
                      <li>
                        You can find the residential documents at your local county office or through your title or
                        escrow company.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="select-option">
  <div class="container">
    <div class="row pt-sm-3 pt-sm-5 pt-md-5 pt-lg-5 pb-5">
      <div class="col-12">
        <h2 class="buyer-faq-title">
          System Purchase or Prepayment FAQs
        </h2>
      </div>

      <div class="col-12 p-0 mt-3 mt-md-5 mt-lg-5">
        <div class="accordion accordion-flush" id="accordionFlush1">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading1-1">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapse1-1" aria-expanded="true" aria-controls="flush-collapse1-1">
                Can I buy my system if I’m not selling my home?
              </button>
            </h2>
            <div id="flush-collapse1-1" class="accordion-collapse collapse" aria-labelledby="flush-heading1-1"
              data-bs-parent="#accordionFlush1">
              <div class="accordion-body">
                Yes! If you just want to buy your system, select
                <a (click)="scrollToSection('system-purchase-or-prepayment-request')" class="scroll-button">“Go To Form”</a> under the
                System Purchase or Prepayment section and complete the required information to submit your request
                online. A quote will be ordered and generally is available in 2-4 business days of submitting your case,
                at which point a copy will be emailed to you. Once you have received the quote, you can reply to the
                email if you would like to move forward with the system purchase process. You will then receive
                instructions to move forward with your system purchase.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading1-2">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapse1-2" aria-expanded="true" aria-controls="flush-collapse1-2">
                What happens if the calculated buyout amount expires?
              </button>
            </h2>
            <div id="flush-collapse1-2" class="accordion-collapse collapse" aria-labelledby="flush-heading1-2"
              data-bs-parent="#accordionFlush1">
              <div class="accordion-body">
                <ul>
                  <li>
                    If your buyout quote has expired, you are able to request a new calculated quote.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading1-3">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapse1-3" aria-expanded="true" aria-controls="flush-collapse1-3">
                Can I prepay for my system?
              </button>
            </h2>
            <div id="flush-collapse1-3" class="accordion-collapse collapse" aria-labelledby="flush-heading1-3"
              data-bs-parent="#accordionFlush1">
              <div class="accordion-body">
                <ul>
                  <li>
                    Yes, can prepay for your system at any time. Select 
                      <a (click)="scrollToSection('system-purchase-or-prepayment-request')" class="scroll-button">
                        “Go To Form”
                      </a>
                      under System Purchase or Prepayment to start the process.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading1-4">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapse1-4" aria-expanded="true" aria-controls="flush-collapse1-4">
                I want to purchase the solar system, whose name will be listed on the bill of sale?
              </button>
            </h2>
            <div id="flush-collapse1-4" class="accordion-collapse collapse" aria-labelledby="flush-heading1-4"
              data-bs-parent="#accordionFlush1">
              <div class="accordion-body">
                <ul>
                  <li>
                    The bill of sale will be listed in the current account holder’s name.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading1-5">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapse1-5" aria-expanded="true" aria-controls="flush-collapse1-5">
                How can I transfer my SREC’S after purchasing my System?
              </button>
            </h2>
            <div id="flush-collapse1-5" class="accordion-collapse collapse" aria-labelledby="flush-heading1-5"
              data-bs-parent="#accordionFlush1">
              <div class="accordion-body">
                <ul>
                  <li>
                    After the system purchase is complete, you will receive a transfer request email to initiate
                    your SREC transfer.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="select-option">
  <div class="container">
    <div class="row pt-sm-3 pt-sm-5 pt-md-5 pt-lg-5 pb-5">
      <div class="col-12">
        <h2 class="buyer-faq-title">
          Home Sale FAQs
        </h2>
      </div>

      <div class="col-12 p-0 mt-3 mt-md-5 mt-lg-5">
        <div class="accordion accordion-flush" id="accordionFlush-Home">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading2-1">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapse2-1" aria-expanded="false" aria-controls="flush-collapse2-1">
                I'm selling my home and I have an existing Solar Lease Contract. What are my options with my lease?
              </button>
            </h2>
            <div id="flush-collapse2-1" class="accordion-collapse collapse" aria-labelledby="flush-heading2-1"
                  data-bs-parent="#accordionFlush-Home">
              <div class="accordion-body">
                <ul>
                  <li>
                    You have three options during a home sale:
                    <ul>
                      <li>
                        <strong>Assignment of Lease: </strong> You can transfer the lease to the new buyer, who will
                        assume the lease as-is and continue to pay the lease payments (if any) with the same terms you
                        have now;
                      </li>
                      <li>
                        <strong>System Purchase: </strong> Alternatively, you can buyout your system, taking ownership
                        of the equipment and selling the system with the sale of your home. This ends the Lease
                        obligation and no further monthly lease payment would be due; OR
                      </li>
                      <li>
                        <strong>Prepayment: </strong> You have the option to prepay the remaining lease payments of the
                        solar lease agreement. This option only covers the lease payments, and the solar equipment is
                        still owned by us, Spruce Power. Therefore, any changes or services to the system are unable to
                        be completed without our consent.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading2-2">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapse2-2" aria-expanded="true" aria-controls="flush-collapse2-2">
                How do I start the process for a home sale Transfer?
              </button>
            </h2>
            <div id="flush-collapse2-2" class="accordion-collapse collapse" aria-labelledby="flush-heading2-2"
              data-bs-parent="#accordionFlush-Home">
              <div class="accordion-body">
                <ul>
                  <li>
                    Select
                    <a (click)="scrollToSection('home-sale-information')" class="scroll-button">
                       “Go To Form”
                    </a>
                     under the Selling Your Home section and complete the required information to submit
                    your request online. That page contains the option to print the form and gives you information on
                    how to submit it. After submission you can expect to be contacted by a case manager in 1-2 business
                    days.
                  </li>
                  <li>
                    <strong>Credit Check: </strong> A credit check/identity verification application will be sent to the
                    buyer within 3-5 business days. This application is mandatory and must be completed by the buyer to
                    assume the lease. You would submit your completed application to <a
                      href="mailto:creditapp@sprucepower.com"> creditapp&#64;sprucepower.com </a> or you can fax the
                    application to <a href="tel:+18324314315"> (832) 431 4315 </a>.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading2-3">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapse2-3" aria-expanded="true" aria-controls="flush-collapse2-3">
                What happens if I get declined?
              </button>
            </h2>
            <div id="flush-collapse2-3" class="accordion-collapse collapse" aria-labelledby="flush-heading2-3"
              data-bs-parent="#accordionFlush-Home">
              <div class="accordion-body">
                <ul>
                  <li>
                    You will have the option to submit another application with a co-applicant, purchase the system, or
                    prepay the system where no credit check is needed. Please give us a call at <a href="+18443198737">
                      (844) 319-8737 </a> so that we can further assist you with this matter.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading2-4">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapse2-4" aria-expanded="true" aria-controls="flush-collapse2-4">
                I’m purchasing a home with a prepaid lease. Why do I need a credit check?
              </button>
            </h2>
            <div id="flush-collapse2-4" class="accordion-collapse collapse" aria-labelledby="flush-heading2-4"
              data-bs-parent="#accordionFlush-Home">
              <div class="accordion-body">
                <ul>
                  <li>
                    With a prepaid lease, there will be no credit check required for the transfer of the solar.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading2-12">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapse2-12" aria-expanded="true" aria-controls="flush-collapse2-12">
                What If I do not want to assume the solar Lease on this home?
              </button>
            </h2>
            <div id="flush-collapse2-12" class="accordion-collapse collapse" aria-labelledby="flush-heading2-12"
              data-bs-parent="#accordionFlush-Home">
              <div class="accordion-body">
                <ul>
                  <li>
                    You cannot cancel or remove the equipment. If you do not wish to assume the lease:
                    <ul>
                      <li>
                        You can purchase the system; or,
                      </li>
                      <li>
                        Spruce Power would be happy to try to assist you in learning more about the lease and the solar
                        system, including the benefits of solar. Please contact us at <a href="tel:+18443198737"> (844)
                          319-8737 </a> with any questions you might have.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading2-5">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapse2-5" aria-expanded="true" aria-controls="flush-collapse2-5">
                I am selling my home through escrow who can complete the escrow home sale process?
              </button>
            </h2>
            <div id="flush-collapse2-5" class="accordion-collapse collapse" aria-labelledby="flush-heading2-5"
              data-bs-parent="#accordionFlush-Home">
              <div class="accordion-body">
                <ul>
                  <li>
                    All escrow and title companies will be able to complete the escrow process.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading2-6">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapse2-6" aria-expanded="true" aria-controls="flush-collapse2-6">
                What closing date is acceptable for an Escrow packet?
              </button>
            </h2>
            <div id="flush-collapse2-6" class="accordion-collapse collapse" aria-labelledby="flush-heading2-6"
              data-bs-parent="#accordionFlush-Home">
              <div class="accordion-body">
                <ul>
                  <li>
                    The closing date must be minimum 6 business days out from receiving all required documents.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading2-7">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapse2-7" aria-expanded="true" aria-controls="flush-collapse2-7">
                Can an account be transferred to an LLC/Company?
              </button>
            </h2>
            <div id="flush-collapse2-7" class="accordion-collapse collapse" aria-labelledby="flush-heading2-7"
              data-bs-parent="#accordionFlush-Home">
              <div class="accordion-body">
                <ul>
                  <li>
                    Prepaid accounts may be transferred to an entity.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading2-8">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapse2-8" aria-expanded="true" aria-controls="flush-collapse2-8">
                I bought my home through foreclosure, how can I use the solar system?
              </button>
            </h2>
            <div id="flush-collapse2-8" class="accordion-collapse collapse" aria-labelledby="flush-heading2-8"
              data-bs-parent="#accordionFlush-Home">
              <div class="accordion-body">
                <ul>
                  <li>
                    You will need to provide your foreclosure documentation and follow the home sale transfer process.
                    Select
                    <a (click)="scrollToSection('home-sale-information')" class="scroll-button">
                       “Go To Form”
                    </a>
                     under the Selling Your Home section and complete the required information to submit
                    your request online.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading2-9">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapse2-9" aria-expanded="true" aria-controls="flush-collapse2-9">
                  Are there any fees associated with the sale of my home?
              </button>
            </h2>
            <div id="flush-collapse2-9" class="accordion-collapse collapse" aria-labelledby="flush-heading2-9"
              data-bs-parent="#accordionFlush-Home">
              <div class="accordion-body">
                <ul>
                  <li>
                    Yes, there is a $150 processing fee if you are selling your home with an Escrow company or
                    <strong>$350.00 </strong>if selling without an Escrow company.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading2-10">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapse2-10" aria-expanded="true" aria-controls="flush-collapse2-10">
                  What is the timeframe for a lease assignment and/or buyout process to be completed?
              </button>
            </h2>
            <div id="flush-collapse2-10" class="accordion-collapse collapse" aria-labelledby="flush-heading2-10"
              data-bs-parent="#accordionFlush-Home">
              <div class="accordion-body">
                <ul>
                  <li>
                      Spruce Power requires a 30-day notice prior to home sale.
                  </li>
                  <li>
                    <u>Lease Assignment </u>– Escrow Solar Documents can be completed within 8-12 business days after
                    credit approval. Signed lease assignment documents are required for release preparation and final
                    payment (if any) must be completed and submitted to Spruce Power in order to complete the assumption
                    process.
                  </li>
                  <li>
                    <u>Buy Out </u>– Escrow Purchase Instructions can be completed within 7-10 business days once
                    confirmation to exercise the buyout quote has been received. Signed purchase documents are required
                    for release preparation. Payment must be completed and submitted to Spruce Power in order to
                    complete the buyout process.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading2-11">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapse2-11" aria-expanded="true" aria-controls="flush-collapse2-11">
                  Does a UCC filing mean there’s a lien on my property?
              </button>
            </h2>
            <div id="flush-collapse2-11" class="accordion-collapse collapse" aria-labelledby="flush-heading2-11"
              data-bs-parent="#accordionFlush-Home">
              <div class="accordion-body">
                <ul>
                  <li>
                      Spruce Power may have filed a Uniform Commercial Code Financing Statement on the solar system, aka a
                      UCC-1 fixture filing, which appears on the title of your home. Please note, this filing is not a
                      lien on your home, rather it is a public record that shows that the solar system installed on your
                      home belongs to the Solar Provider. Some financing providers may ask to see additional documentation
                      about the UCC filing.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="select-option">
  <div class="container">
    <div class="row pt-sm-3 pt-sm-5 pt-md-5 pt-lg-5 pb-5">
      <div class="col-12">
        <h2 class="refinance-faq-title">
            Refinance FAQs
        </h2>
      </div>

      <div class="col-12 p-0 mt-3 mt-md-5 mt-lg-5">
        <div class="accordion accordion-flush" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-Seven">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseEleven" aria-expanded="true" aria-controls="flush-collapseEleven">
                  I'm refinancing my home; how should I start the process?
              </button>
            </h2>
            <div id="flush-collapseEleven" class="accordion-collapse collapse" aria-labelledby="flush-Seven"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <ul>
                  <li>
                      You’ve already completed the first step by visiting
                      <a [routerLink]="['/life-events']">https://sprucepower.com/life-events</a>
                  </li>
                  <li>
                    Please click
                    <a (click)="scrollToSection('refinance-information-request')" class="scroll-button">
                      “Go To Form”
                    </a>
                    under the Refinance section, and
                    complete the required information to submit your request
                    online. You can expect to be contacted by
                    a case manager in 1-2 business days. Alternatively, you can
                    download the form, fill it in electronically or by hand, and
                    email it (and the required attachments) to the Spruce Asset
                    Disposition Team at
                    <a href="mailto:refinance@sprucepower.com">refinance&#64;sprucepower.com</a>.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-nine">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                  Are there any fees associated with refinancing requests?
              </button>
            </h2>
            <div id="flush-collapseThirteen" class="accordion-collapse collapse" aria-labelledby="flush-Nine"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <ul>
                  <li>
                    Yes, there is a $350 processing fee plus recording fees if Spruce records the terminations.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-ten">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFourteen" aria-expanded="false" aria-controls="flush-collapseFourteen">
                  Can I request a UCC termination during my refinancing?
              </button>
            </h2>
            <div id="flush-collapseFourteen" class="accordion-collapse collapse" aria-labelledby="flush-ten"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <ul>
                  <li>
                      Yes! Upon your request, we are able to temporarily terminate and re-file these documents to enable
                      your refinancing to proceed. We recommend contacting us within 30 days, and at least 3 weeks prior,
                      to the expected close date. To process this request there is an upfront service charge. Our Asset
                      Disposition team will be happy to walk you through the process and can help with any other questions
                      you may have about the UCC filing.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-4-4">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapse4-4" aria-expanded="false" aria-controls="flush-collapse4-4">
                  How can I request termination of the UCC filing?
              </button>
            </h2>
            <div id="flush-collapse4-4" class="accordion-collapse collapse" aria-labelledby="flush-4-4"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <ul>
                  <li>
                    Click <a (click)="scrollToSection('refinance-information-request')" class="scroll-button"> “Go To Form”</a>
                    under the Refinancing your home section, and complete the required information to submit your
                    request online. You can expect to be contacted by a team member in 1-2 business days.
                  </li>
                  <li>
                    To avoid potential delays with your request, please be sure to attach the following documents before
                    you click “Submit”:
                    <ul>
                      <li>
                        Recorded copy of the fixture filing you need to have terminated.
                      </li>
                      <li>
                        Preliminary title report or title commitment.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-4-5">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapse4-5" aria-expanded="false" aria-controls="flush-collapse4-5">
                  My lender is asking for subordination. How do I request this?
              </button>
            </h2>
            <div id="flush-collapse4-5" class="accordion-collapse collapse" aria-labelledby="flush-4-5"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <ul>
                  <li>
                      If the lender or title company requires a subordination, please submit a request online and a team
                      member will reach out to help.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-twelve">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSixteen" aria-expanded="false" aria-controls="flush-collapseSixteen">
                  Will UCC filing be reinstated after refinance?
              </button>
            </h2>
            <div id="flush-collapseSixteen" class="accordion-collapse collapse" aria-labelledby="flush-twelve"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <ul>
                  <li>
                      Spruce Power will refile UCC 30 days after termination of the
                      original filings.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="form-select-option mt-4">
  <div class="container pt-5 pb-5">
    <div class="row pt-5 pb-5 text-center justify-content-center">
      <div class="col-12">
        <div class="question-section">
          <h1 class="mb-5">Other Questions or Help?</h1>
          <div class="refinancing-contactus-button">
            <a [routerLink]="['/get-in-touch']">Contact Us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
